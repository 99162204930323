@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Poppins';
  src: url('assets/fonts/Poppins-Light.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'Nunito-Regular';
  src: url('assets/fonts/Nunito-Regular.ttf') format('truetype');
  font-style: normal;
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-style: normal;
}
body {
  overflow-x: hidden;
  height: 100vh;
  width: 100%;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 15px;
  }
  &::-webkit-scrollbar-thumb {
    background: rgb(99, 94, 94);
    border-left: 2px solid white;
    border-right: 2px solid white;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    border-left: 6.5px solid transparent;
    border-right: 6.5px solid transparent;
    background: transparent;
  }
}
/* AI messages (Incoming) */
.cs-message--incoming .cs-message__content {
  background-color: #6e3ef5 !important;
  color: white !important;
  border-radius: 0.7em 0.7em 0.7em 0.7em !important;
  font-size: 12px !important;
}
.cs-chat-container .cs-message-input {
  padding: 0.3em 0.3em 0.3em 0.3em !important;
  margin-top: 6px !important;
  margin-bottom: 6px !important;
}
.cs-message-input__content-editor-wrapper {
  background-color: #f3f3f3 !important;
}
.cs-message-input__content-editor {
  background-color: #f3f3f3 !important;
}
/* User messages (Outgoing) */
.cs-message--outgoing .cs-message__content {
  background-color: #f3f3f3 !important;
  color: black !important;
  border-radius: 0.7em 0.7em 0.7em 0.7em !important;
  font-size: 12px !important;
}

a {
  text-decoration: none;
  cursor: pointer;
}
.cs-conversation-header__content .cs-conversation-header__user-name {
  background-color: #ece8ff !important;
}

input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none !important;
  outline: none;
  box-shadow: none !important;
  color: none !important; /* Keeps text color consistent */
}

/* Autofill reset */

input:-webkit-autofill {
  background-color: transparent !important;
  color: inherit !important;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
  transition: background-color 9999s ease-in-out 0s; /* Delays autofill background */
}
